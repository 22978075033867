import React from 'react';
import { Button, Flex } from 'rebass/styled-components';
import { Layout } from '../../../components/Layout';
import Section from '../../../components/Section';
import ContextProvider from '../../../components/ContextProvider';
import { H3, Paragraph } from '../../../components/TextLibrary';
import { getUtm } from '../../../utils/Utm';

const FWA2022Promo = (): JSX.Element => {
  const utm = getUtm();

  return (
    <ContextProvider localeCode="en-AU">
      <Layout
        colorTheme="light"
        title="Fitness + Wellness 2022 - Special Offer"
        description={`Redeem your 3 month free trial of CloudFit`}
        noIndex={true}
      >
        <Section.Container id="404" backgroundColor="light" mt={[5, 6]}>
          <H3 color="primaryDark" as="h1" pb={[2, 3]}>
            Fitness + Wellness 2022
          </H3>
          <Flex flexDirection="column" alignItems="center">
            <Paragraph px={[3, 5]} pb={[3]}>
              Thanks for visiting us at The Fitness + Wellness Expo 2022. To
              claim your three month trial simply provide us some contact
              details and one of our team members will get in contact with you.
            </Paragraph>
            <Button
              type="button"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `https://cloudfit.typeform.com/to/mMkxGlxO?utm_source=${utm.source}&utm_medium=${utm.medium}&utm_campaign=${utm.campaign}`,
                );
              }}
            >
              Get my trial
            </Button>
          </Flex>
        </Section.Container>
      </Layout>
    </ContextProvider>
  );
};

export default FWA2022Promo;
